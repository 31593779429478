<template>
  <div class="features">
    <div class="features_center">
      <!-- <h1>特色项目</h1> -->
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ routerName }}</el-breadcrumb-item>
          <!-- <el-breadcrumb-item v-if="nowtitle !== null ">{{ nowtitle }}</el-breadcrumb-item> -->
        </el-breadcrumb>
      </header>
      <div class="feats">
        <aside>
          <div>
            <div class="fealist" v-for="item in fealistData" :key="item.id">
              <p class="featpt" @click="cutdata(item.id,item.title)">{{item.title}}</p>
              <p class="featpc">{{item.content}}</p>
              <div class="feaIcon">
                <div class="feaLeft">
                  <p class="i_like">
                    <i></i>
                    <span>{{item.like}}</span>
                  </p>
                  <p class="i_collect">
                    <i></i>
                    <span>{{item.collect}}</span>
                  </p>
                  <p class="i_pinglun">
                    <i></i>
                    <span>{{item.pinglun}}</span>
                  </p>
                </div>
                <div class="feaRight">
                  <p>
                    <i class="i_userin"></i>
                    <span class="i_user">{{item.user}}</span>
                  </p>
                  <p>
                    <span class="i_time">{{item.time}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="curpage"
            :page-size="pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </aside>
        <main>
          <ul>
            <li :class="nownum==1?'blue':'blank'" @click="cutli(1)">卓越教师培养</li>
            <li :class="nownum==2?'blue':'blank'" @click="cutli(2)">导师带教</li>
            <li :class="nownum==3?'blue':'blank'" @click="cutli(3)">博研苑</li>
            <li :class="nownum==4?'blue':'blank'" @click="cutli(4)">市级研修共同体</li>
            <li :class="nownum==5?'blue':'blank'" @click="cutli(5)">学区工作站</li>
            <li :class="nownum==6?'blue':'blank'" @click="cutli(6)">教科院十大项目</li>
            <li :class="nownum==7?'blue':'blank'" @click="cutli(7)">大学部建设</li>
          </ul>
          <div class="hotlist">
            <p class="hottwxt">热门话题</p>
            <div class="hottext">
              <p v-for="item in hotlistdata" :key="item.id" @click="cutdata(item.id,item.title)">{{item.title}}</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import myEditor from "../../components/myeditor.vue";
export default {
  components: {
    myEditor,
  },
  data() {
    return {
      // 面包屑
      routerName: null,
      // 列表数据
      fealistData: null,
      // 热门话题列表
      hotlistdata: null,
      //
      nownum: 1,
      // 分页
      total: null,
      pagesize: 10,
      curpage: 1,
    };
  },
  created() {
    console.log(this.$route.meta.title, "路由");
    this.routerName = this.$route.meta.title;
    //
    this.getfeatures();
    this.gethotlist();
  },
  methods: {
    //获取列表数据
    getfeatures() {
      let aa = [
        //
        // {
        //   id: 1,
        //   title: "推动卓越教师培养计划",
        //   content:
        //     "为增加朝阳区在职教师的职业水平和素养，朝阳区教育委员会正式提出卓越培养计划，为教育事业添砖加瓦",
        //   like: 200,
        //   collect: 100,
        //   pinglun: 100,
        //   user: "刘老师",
        //   time: "2022-03-07 20:20",
        // },
        {
          id: 2,
          title: "关于区域卓越教师队伍建设的探讨",
          content:
            "北京市朝阳区持续推动，促进区域卓越教师队伍的建设，目前朝阳区教育系统已经完成了规模较大、规格较高的人才培训与储备。对于建设卓越教师队伍您有何看法或建议，或者您认为的卓越教师应该符合什么标准？请大家畅所欲言。",
          like: 200,
          collect: 100,
          pinglun: 100,
          user: "刘老师",
          time: "2022-03-07 20:20",
        },
      ];
      this.fealistData = aa;
      this.total = aa.length;
    },
    //热门话题列表
    gethotlist() {
      let aa = [
        //
        {
          id: 1,
          title: "推动卓越教师培养计划",
        },
        // {
        //   id: 2,
        //   title: "关于区域卓越教师队伍建设的探讨",
        // },
      ];
      this.hotlistdata = aa;
    },
    //切换数据
    cutdata(num, text) {
      console.log(num, "切换数据");
      this.$router.push({
        path: "/featuresinfo",
        query: {
          id: num,
          title: text,
        },
      });
    },
    // 切换
    cutli(num) {
      this.nownum = num;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.features {
  .features_center {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
    .feats {
      display: flex;
      justify-content: space-between;
      aside {
        width: 727px;
        // height: 1918px;
        min-height: 600px;
        background: #ffffff;
        padding: 0 30px;
        .fealist {
          // margin-top: 10px;
          border-bottom: 1px solid #d7dce4;
          padding: 30px 0 10px;
          .featpt {
            width: 280px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 600;
            color: #141516;
            // line-height: 16px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .featpc {
            width: 720px;
            // height: 56px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            // line-height: 14px;
            margin-top: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .feaIcon {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            .feaLeft {
              display: flex;
              justify-content: space-around;
              p {
                width: 50px;
              }
              .i_like {
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: url("../../assets/feature/like.png") no-repeat;
                  vertical-align: middle;
                }
              }
              .i_collect {
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: url("../../assets/feature/collect.png") no-repeat;
                  vertical-align: middle;
                }
              }
              .i_pinglun {
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: url("../../assets/feature/pinglun.png") no-repeat;
                  vertical-align: middle;
                }
              }
              span {
                margin-left: 3px;
                display: inline-block;
                width: 22px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d9199;
                line-height: 14px;
                vertical-align: middle;
              }
            }
            .feaRight {
              display: flex;
              justify-content: space-between;
              p {
                width: 100px;
              }
              .i_userin {
                display: inline-block;
                width: 39px;
                height: 39px;
                background: url("../../assets/feature/user.png") no-repeat;
                vertical-align: middle;
              }
              .i_user {
                display: inline-block;
                width: 36px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5d6066;
                line-height: 14px;
                // vertical-align: middle;
                margin-left: 5px;
              }
              .i_time {
                display: inline-block;
                width: 102px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d9199;
                line-height: 39px;
              }
            }
          }
        }
      }
      main {
        width: 392px;
        // max-height: 499px;

        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        ul {
          list-style: none;
          padding: 0px 20px;
          background: #ffffff;
          li {
            border-bottom: 1px solid #d2d2d2;
            height: 70px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            line-height: 71px;
            cursor: pointer;
          }
          .blank {
            color: #141516;
          }
          .blue {
            color: #1977f9;
          }
          li:hover {
            color: #1977f9;
          }
          :nth-child(7) {
            border: 0;
          }
        }
        .hotlist {
          background: #ffffff;
          margin-top: 20px;
          padding: 15px 20px;
          .hottwxt {
            // width: 72px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            line-height: 21px;
            border-bottom: 1px solid #d7dce4;
            padding: 10px 0;
          }
          .hottext {
            padding: 10px 0;
            p {
              width: 320px;
              height: 30px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              line-height: 30px;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:hover {
              color: #1977f9;
            }
          }
        }
      }
    }
    .el-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }
  }
}
</style>